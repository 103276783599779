
<div class="logoContainer">
<h1>CHRIS JACOBS IS CODEBURO.</h1>
	<!-- <h2>About me</h2> -->
	<p>
		I'm a freelance software developer who specializes in complex database driven web applications and back-end solutions for large and smaller companies.<br/>
		<a href="https://www.linkedin.com/in/christian-jacobs-b987a660" title="my LinkedIn page" target="_blank">Go to my LinkedIn page</a> to get in touch or learn more about me.
	</p>
	<p class="contactDetails">
		<a href="mailto:contact@codeburo.nl">contact@codeburo.nl</a><br/>
	</p>
</div>
