import {Component, HostListener, OnInit} from '@angular/core';
import * as THREE from 'three';
import {BufferGeometry, DirectionalLight, LineSegments, Material, Mesh, PerspectiveCamera, Points, WebGLRenderer} from 'three';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'codeburo';

  private container: Element;
  private scene: THREE.Scene;
  private camera: PerspectiveCamera;
  private mesh: Mesh;
  private renderer: WebGLRenderer;
  private directionalLight: DirectionalLight;
  private group: any;
  private maxParticleCount = 300;
  private particleCount = 50;
  private r = 800;
  private rHalf = this.r / 2;
  private positions: Float32Array;
  private colors: Float32Array;
  private particles: BufferGeometry;
  private particlePositions: Float32Array;
  private particlesData = [];
  private pointCloud: Points;
  private linesMesh: LineSegments;

  private effectController = {
    showDots: true,
    showLines: true,
    minDistance: 150,
    limitConnections: true,
    maxConnections: 1,
    particleCount: 500
  };

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.container = document.querySelector('#scene-container');
    this.camera = new THREE.PerspectiveCamera(45, this.container.clientWidth / this.container.clientHeight, 1, 4000);
    // this.camera.position.set(0, 0, 1750);
    this.camera.position.set(0, 0, 600);
    this.scene = new THREE.Scene();
    // this.scene.background = new THREE.Color(0xf0eceb);
    this.scene.background = new THREE.Color(0x000000);
    this.group = new THREE.Group();
    this.scene.add(this.group);
    const boxhelperMaterial = new THREE.Material();
    boxhelperMaterial.blending = THREE.AdditiveBlending;
    boxhelperMaterial.transparent = true;
    const boxhelper = new THREE.BoxHelper( new THREE.Mesh( new THREE.BoxBufferGeometry( this.r, this.r, this.r ), boxhelperMaterial )
      , 0x101010 );
    // this.group.add( boxhelper );

    const segments = this.maxParticleCount * this.maxParticleCount;
    this.positions = new Float32Array(segments * 3);
    this.colors = new Float32Array(segments * 3);

    const pMaterial = new THREE.PointsMaterial({
      color: 0xff3e00,
      size: 3,
      // blending: THREE.AdditiveBlending,
      transparent: false,
      sizeAttenuation: false
    });

    this.particles = new THREE.BufferGeometry();
    this.particlePositions = new Float32Array(this.maxParticleCount * 3);

    for ( let i = 0; i < this.maxParticleCount; i ++ ) {

      const x = Math.random() * this.r - this.r / 2;
      const y = Math.random() * this.r - this.r / 2;
      const z = Math.random() * this.r - this.r / 2;

      this.particlePositions[ i * 3 ] = x;
      this.particlePositions[ i * 3 + 1 ] = y;
      this.particlePositions[ i * 3 + 2 ] = z;

      // add it to the geometry
      const speedmultibla = 0.1;
      const speed = 1 * speedmultibla;
      const speedmultiplier = 2 * speedmultibla;
      this.particlesData.push( {
        velocity: new THREE.Vector3( - speed + Math.random() * speedmultiplier
          , - speed + Math.random() * speedmultiplier
          , - speed + Math.random() * speedmultiplier ),
        numConnections: 0
      } );

    }

    this.particles.setDrawRange( 0, this.particleCount );
    this.particles.setAttribute( 'position', new THREE.BufferAttribute( this.particlePositions, 3 ).setUsage( THREE.DynamicDrawUsage ) );

    // create the particle system
    this.pointCloud = new THREE.Points( this.particles, pMaterial );
    this.group.add( this.pointCloud );

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute( 'position', new THREE.BufferAttribute( this.positions, 3 ).setUsage( THREE.DynamicDrawUsage ) );
    geometry.setAttribute( 'color', new THREE.BufferAttribute( this.colors, 3 ).setUsage( THREE.DynamicDrawUsage ) );
    geometry.computeBoundingSphere();
    geometry.setDrawRange( 0, 0 );
    const material = new THREE.LineBasicMaterial( {
      vertexColors: true,
      color: 0xffeae1,
      // blending: THREE.AdditiveBlending, // Turn this off if I want colors
      // transparent: true
    } );

    this.linesMesh = new THREE.LineSegments( geometry, material );
    this.group.add( this.linesMesh );

    this.renderer = new THREE.WebGLRenderer( { antialias: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight );
    this.renderer.outputEncoding = THREE.sRGBEncoding;

    this.container.appendChild( this.renderer.domElement );

    this.renderer.setAnimationLoop(() => {
      this.update();
      this.render();
    });

    this.onResize(null); // Need to call this otherwise blank screen or untill resize
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // event.target.innerWidth;
    // set the aspect ratio to match the new browser window aspect ratio
    this.camera.aspect = this.container.clientWidth / this.container.clientHeight;

    // update the camera's frustum
    this.camera.updateProjectionMatrix();

    // update the size of the renderer AND the canvas
    this.renderer.setSize( this.container.clientWidth, this.container.clientHeight );
  }

  update() {
    // this.camera.position.setZ(this.camera.position.z + 1);

    let vertexpos = 0;
    let colorpos = 0;
    let numConnected = 0;

    for ( let i = 0; i < this.particleCount; i ++ ) {
      this.particlesData[i].numConnections = 0;
    }
    for ( let i = 0; i < this.particleCount; i ++ ) {

      // get the particle
      const particleData = this.particlesData[ i ];

      this.particlePositions[ i * 3 ] += particleData.velocity.x;
      this.particlePositions[ i * 3 + 1 ] += particleData.velocity.y;
      this.particlePositions[ i * 3 + 2 ] += particleData.velocity.z;

      if ( this.particlePositions[ i * 3 + 1 ] < - this.rHalf || this.particlePositions[ i * 3 + 1 ] > this.rHalf ) {
        particleData.velocity.y = - particleData.velocity.y;
      }
      if ( this.particlePositions[ i * 3 ] < - this.rHalf || this.particlePositions[ i * 3 ] > this.rHalf ) {
        particleData.velocity.x = - particleData.velocity.x;
      }
      if ( this.particlePositions[ i * 3 + 2 ] < - this.rHalf || this.particlePositions[ i * 3 + 2 ] > this.rHalf ) {
        particleData.velocity.z = - particleData.velocity.z;
      }

      if ( this.effectController.limitConnections && particleData.numConnections >= this.effectController.maxConnections ) {
        continue;
      }

      // Check collision
      for ( let j = i + 1; j < this.particleCount; j ++ ) {

        const particleDataB = this.particlesData[ j ];
        if ( this.effectController.limitConnections && particleDataB.numConnections >= this.effectController.maxConnections ) {
          continue;
        }

        const dx = this.particlePositions[ i * 3 ] - this.particlePositions[ j * 3 ];
        const dy = this.particlePositions[ i * 3 + 1 ] - this.particlePositions[ j * 3 + 1 ];
        const dz = this.particlePositions[ i * 3 + 2 ] - this.particlePositions[ j * 3 + 2 ];
        const dist = Math.sqrt( dx * dx + dy * dy + dz * dz );

        if ( dist < this.effectController.minDistance ) {

          particleData.numConnections ++;
          particleDataB.numConnections ++;

          const alpha = 1.0 - dist / this.effectController.minDistance;

          this.positions[ vertexpos ++ ] = this.particlePositions[ i * 3 ];
          this.positions[ vertexpos ++ ] = this.particlePositions[ i * 3 + 1 ];
          this.positions[ vertexpos ++ ] = this.particlePositions[ i * 3 + 2 ];

          this.positions[ vertexpos ++ ] = this.particlePositions[ j * 3 ];
          this.positions[ vertexpos ++ ] = this.particlePositions[ j * 3 + 1 ];
          this.positions[ vertexpos ++ ] = this.particlePositions[ j * 3 + 2 ];

          this.colors[ colorpos ++ ] = alpha;
          this.colors[ colorpos ++ ] = alpha;
          this.colors[ colorpos ++ ] = alpha;

          this.colors[ colorpos ++ ] = alpha;
          this.colors[ colorpos ++ ] = alpha;
          this.colors[ colorpos ++ ] = alpha;

          numConnected ++;

        }

      }

    }

    (this.linesMesh as any).geometry.setDrawRange( 0, numConnected * 2 );
    (this.linesMesh as any).geometry.attributes.position.needsUpdate = true;
    (this.linesMesh as any).geometry.attributes.color.needsUpdate = true;

    (this.pointCloud as any).geometry.attributes.position.needsUpdate = true;


  }

  render() {
    const timeandsome = Date.now() * 0.001;

    this.group.rotation.y = timeandsome * 0.001;
    this.renderer.render( this.scene, this.camera );
  }

}
